import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../assets/css/main.css';
import Sidebarkiri from '../components/sidebarkiri';
import Sidebarkanan from '../components/sidebarkanan';


class Main extends Component {


    render() {
        return (
            <div className="body">
                <div className="bungkus">
                    <div className="anak">
                        <div className="row justify-content-center">
                            <div className="col-lg-3 col-12 kartu">
                                <Sidebarkiri />

                            </div>

                            <div className=" col-lg-8 col-12 kartu">
                                <Sidebarkanan />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default Main;