import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Foto from './foto-profile';
import '../assets/css/main.css';

function Quote() {
    return (
        <div>
            <div className="nama">
                <h3>Brilian Wicaksana</h3>
            </div>
            <div className="worknow text-center">
                <h6>
                    Learner and Web developer
                        </h6>
            </div>
            <div className="quote text-center ">
                <small>"coding is not just typing, but to become a super hero"</small>
            </div>
        </div>
    )
}

class Sosmed extends Component {

    // state = {
    //     facebook: '#3b5998',
    //     instagram: '#C13584',
    //     linkedin: '#0072b1',
    //     github: '#4078c0'
    // }
    daftarsosmed = () => {
        return (
            <div>
                <div>
                    <div className="row sosmed justify-content-center mx-auto">
                        <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/brilianpmw">
                            <div className="bungkus-icon">
                                <div className="bungkus-icon-lv2">
                                    <span style={{ color: '#C13584' }}>
                                        <i className="fab fa-instagram-square"></i>
                                    </span>
                                </div>
                            </div>
                        </a>
                        <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/brilian-wicaksana-677257ab">
                            <div className="bungkus-icon">
                                <div className="bungkus-icon-lv2">
                                    <span style={{ color: '#0072b1' }}>
                                        <i className="fab fa-linkedin"></i>
                                    </span>
                                </div>
                            </div>
                        </a>
                        <a rel="noopener noreferrer" target="_blank" href="https://github.com/brilianpmw">
                            <div className="bungkus-icon">
                                <div className="bungkus-icon-lv2">
                                    <span style={{ color: ' #4078c0' }}>
                                        <i className="fab fa-github-square"></i>
                                    </span>
                                </div>
                            </div>
                        </a>
                        <a rel="noopener noreferrer" target="_blank" href="https://facebook.com/brilian.pmw">
                            <div className="bungkus-icon">
                                <div className="bungkus-icon-lv2">
                                    <span style={{ color: ' #3b5998' }}>
                                        <i className="fab fa-facebook-square"></i>
                                    </span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div >
            </div >
        )
    }


    render() {

        return (
            <div>
                <this.daftarsosmed />
            </div>
        )
    }
}


class sidebarkiri extends Component {
    render() {
        return (
            <div>
                <Foto />
                <Quote />
                <Sosmed />
            </div>
        )
    }
}

export default sidebarkiri;