import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../assets/css/main.css';
import CardProject from './card-mywork';
import '../assets/css/card.css';

function Mywork() {
    return (
        <div>
            <div className="row mt-4">
                <div className="col-12">
                    <h5 className="bawahmenuh5">My Work</h5>
                    <hr className="bawahmenu" />
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12 isian">

                        <CardProject name="wow" />



                    </div>
                </div>
            </div>
        </div>
    )
}
export default Mywork;