import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../assets/css/main.css';
import gambarexperience from '../assets/icon/experience.svg'

const Experience = () => {
    return (
        <div>
            <div className="row mt-3">
                <div className="col-12 col-md-3 icon-text">
                    <img className="header-icon tempelX" src={gambarexperience} alt="icon" />
                </div>
                <div className="col-12 col-md-8 atas-tempel">
                    <h5 className="bawahmenuh5 ">
                        Experience     </h5>
                </div>

            </div>
            <div className="row justify-content-center ">
                <div className="col-12 text-center">
                    <p className="font-weight-bold">Freelance</p>
                    <p className="deskripsi-experience">
                        do some application orders and application improvements
                    </p>
                    <p className="tahun-experience">mid 2019-now</p>
                </div>
            </div>
        </div>
    )
}

export default Experience;