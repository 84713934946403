import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../assets/css/main.css';



const CardProject = (props) => {
    let data = [{
        id: 1,
        urlimg: 'http://brilian.web.id/imgstatic/imigrasi.png',
        nama: 're design and rebuild Imigrasi App indonesia',
        kategori: 'easy',
        buildwith: ['fab fa-html5 ukuran', 'fab fa-css3 ukuran', 'fab fa-js ukuran', 'fab fa-bootstrap ukuran']
    },
    {
        id: 2,
        urlimg: 'http://brilian.web.id/imgstatic/citizen.png',
        nama: 'SIWA - Citizen information system',
        kategori: 'intermediate',
        buildwith: ['fab fa-html5 ukuran', 'fab fa-css3 ukuran', 'fab fa-js ukuran', 'fab fa-bootstrap ukuran', 'fab fa-database ukuran', 'fab fa-python ukuran']
    },
    {
        id: 3,
        urlimg: 'http://brilian.web.id/imgstatic/siperu.png',
        nama: 'SIPERU - New Student regristation Apps',
        kategori: 'intermediate',
        buildwith: ['fab fa-html5 ukuran', 'fab fa-css3 ukuran', 'fab fa-js ukuran', 'fab fa-database ukuran', 'fab fa-php ukuran']
    },
    {
        id: 4,
        urlimg: 'http://brilian.web.id/imgstatic/kelasku.png',
        nama: 'KelasKu - Online learning Apps',
        kategori: 'Hard',
        buildwith: ['fab fa-html5 ukuran', 'fab fa-css3 ukuran', 'fab fa-bootstrap ukuran', 'fab fa-js ukuran', 'fab fa-database ukuran', 'fab fa-node-js ukuran', 'fab fa-video ukuran', 'fab fa-react ukuran']
    }
    ]

    let muncul = data.map((data, key) =>
        <>
            <div key={key} className="col-12 col-md-5 mx-2 mb-5 kotak-work">

                <div className="kotak-gambar">

                    <img className="gambar-work" alt={data.nama} src={(`${data.urlimg}`)} />
                    <h4 className="text-center bawahmenu5 text-card-work">{data.nama}</h4>

                </div>


                <div className="bawahan">
                    <p className="text-center text-muted mt-2">{data.kategori}</p>

                    <div className="row justify-content-center">

                        <div className="col-8">
                            <div className="row justify-content-center">
                                {data.buildwith.map((data, key) =>

                                    <>
                                        <div key={key} className="col-1 tempat-icons-work">
                                            <i className={data}></i>
                                        </div>
                                    </>
                                )}


                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center mt-4">
                        <div className="col-4 ">
                            <a className="btn btn-outline-dark text-center" href="">detail</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    return (
        <>
            <div className="row justify-content-center mt-5">

                {muncul}
            </div>

        </>
    )
}

export default CardProject;