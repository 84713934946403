
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../assets/css/main.css';
import gambareducation from '../assets/icon/diploma.svg'

const Education = () => {
    return (
        <div>
            <div className="row mt-3">
                <div className="col-12 col-md-3 icon-text">
                    <img className="header-icon tempelX" src={gambareducation} alt="icon" />
                </div>
                <div className="col-12 col-md-3 atas-tempel">
                    <h5 className="bawahmenuh5">Education</h5>

                </div>

            </div>
            <div className="text-center row justify-content-center">
                <div className="col-12">
                    <p className="font-weight-bold">Gunadarma University</p>
                    <p className="sekolah-tingkat">College</p>
                    <p className="tahun-sekolah">2017 - Now</p>

                </div>
                <div className="col-12">
                    <p className="font-weight-bold">SMAN 13 Kab Tangerang</p>
                    <p className="sekolah-tingkat">High School Graduate</p>
                    <p className="tahun-sekolah">2013 - 2016</p>

                </div>
            </div>
        </div>
    )
}

export default Education;