import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../assets/css/main.css';
import gambarhobby from '../assets/icon/jigsaw.svg';
import berenang from '../assets/icon/swimming-pool.svg';
import masak from '../assets/icon/chef.svg';
import game from '../assets/icon/console.svg';
import film from '../assets/icon/video.svg';

const Hobby = () => {
    return (
        <div>
            <div className="row mt-3">
                <div className="col-12 col-md-3 icon-text">
                    <img className="header-icon tempelX" src={gambarhobby} alt="icon" />
                </div>
                <div className="col-12 col-md-3 atas-tempel">
                    <h5 className="bawahmenuh5">Hobby</h5>

                </div>

            </div>
            <div className="row text-center mt-3 justify-content-center">
                <div className="col-md-5 col-12">
                    <div className="row text-center justify-content-center kiri">
                        <div className="col-12 atas-hobby ">
                            <img className="header-icon tempelX" src={film} alt="icon" />
                        </div>
                        <div className="col-12">
                            <p className="text-hobby">Movies</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 col-12">
                    <div className="row text-center kanan">
                        <div className="col-12 atas-hobby ">
                            <img className="header-icon tempelX" src={berenang} alt="icon" />
                        </div>
                        <div className="col-12">
                            <p className="text-hobby">Swim</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 col-12">
                    <div className="row text-center justify-content-center kiri">
                        <div className="col-12 atas-hobby ">
                            <img className="header-icon tempelX" src={masak} alt="icon" />
                        </div>
                        <div className="col-12">
                            <p className="text-hobby">Cook</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 col-12">
                    <div className="row text-center kanan">
                        <div className="col-12 atas-hobby ">
                            <img className="header-icon tempelX" src={game} alt="icon" />
                        </div>
                        <div className="col-12">
                            <p className="text-hobby">game</p>
                        </div>
                    </div>
                </div>



            </div>

        </div>
    )
}

export default Hobby;