import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../assets/css/main.css';
import gambarprofile from '../assets/icon/young.svg'


const Profile = () => {
    return (
        <div>
            <div className="row mt-3">
                <div className="col-12 col-md-3 icon-text">
                    <img className="header-icon tempelX" src={gambarprofile} alt="icon" />
                </div>
                <div className="col-12 col-md-3 atas-tempel">
                    <h5 className="bawahmenuh5">Profile</h5>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-9 offset-md-3">
                    <p className="text-about ">
                        I am a web developer, I can do front end or back end with certain programming languages, I always make websites that are responsive and easy to use. I am always interested in the latest technology and challenges
                </p>

                </div>
            </div>
        </div>
    )
}
export default Profile;