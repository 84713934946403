import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../assets/css/main.css';

function Touchme() {
    return (
        <div>
            <div className="row mt-4">
                <div className="col-12">
                    <h5 className="bawahmenuh5">Touch Me</h5>
                    <hr className="bawahmenu" />
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12 isian">
                        <h1 className="text-center text-black">Coming soon Touchme</h1>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default Touchme;