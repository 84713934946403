import React from 'react';

import '../assets/css/main.css';
import imgprofile from '../assets/img/Profile.jpg';
import 'bootstrap/dist/js/bootstrap.min.js';

const foto = () => {
    return (
        <div>
            <div className="bungkus-ava mx-auto mt-5">
                <div className="bungkus-ava-lv2 ">
                    <img src={imgprofile} height="200px" width="200px" alt="..." />
                </div>
            </div>
        </div>
    )
}
export default foto;