import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../assets/css/main.css';
import gambarskill from '../assets/icon/worker.svg';
import '../assets/css/bar.css';

// function Progreskil(props) {
//     return (
//         <div>
//             <div className="col-10">
//                 <div className="html-container mt-2 ">
//                     <div className="bungkusan">
//                         <h2>{props.namaskill}</h2>
//                     </div>
//                     <div className="loader">
//                         <div style={props.style} className="html-loader"></div>
//                     </div>
//                     <div className="percent">{props.persen}</div>
//                 </div>
//             </div>
//         </div>
//     )
// }

class Skill extends Component {
    render() {


        return (
            <div>
                <div className="row mt-3">
                    <div className="col-12 col-md-2 icon-text ">
                        <img className="header-icon tengahin" src={gambarskill} alt="icon" />
                    </div>
                    <div className="col-12 col-md-8 atas-tempel">
                        <h5 className="skillcoy bawahmenuh5">Skill</h5>
                    </div>
                </div>
                <div className="row  mt-3">
                    <div className="col-md-6 col-12 mb-2">
                        <h6 className="bawahmenuh5">front end</h6>
                        <div className="row">
                            <div className="col-12">
                                <div className="html-container mt-2 ">
                                    <div className="bungkusan">
                                        <h2>HTML</h2>
                                    </div>
                                    <div className="loader">
                                        <div style={{ width: "90%" }} className="html-loader"></div>
                                    </div>
                                    <div className="percent">90%</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="html-container mt-2 ">
                                    <div className="bungkusan">
                                        <h2>CSS</h2>
                                    </div>
                                    <div className="loader">
                                        <div style={{ width: "80%" }} className="html-loader"></div>
                                    </div>
                                    <div className="percent">80%</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="html-container mt-2 ">
                                    <div className="bungkusan">
                                        <h2>JS</h2>
                                    </div>
                                    <div className="loader">
                                        <div style={{ width: "70%" }} className="html-loader"></div>
                                    </div>
                                    <div className="percent">70%</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="html-container mt-2 ">
                                    <div className="bungkusan">
                                        <h2>Bootstrap</h2>
                                    </div>
                                    <div className="loader">
                                        <div style={{ width: "85%" }} className="html-loader"></div>
                                    </div>
                                    <div className="percent">85%</div>
                                </div>

                            </div>
                            <div className="col-12">
                                <div className="html-container mt-2 ">
                                    <div className="bungkusan">
                                        <h2>ReactJS</h2>
                                    </div>
                                    <div className="loader">
                                        <div style={{ width: "85%" }} className="html-loader"></div>
                                    </div>
                                    <div className="percent">85%</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 ">
                        <h6 className="bawahmenuh5">back end</h6>
                        <div className="row">
                            <div className="col-12">
                                <div className="html-container mt-2 ">
                                    <div className="bungkusan">
                                        <h2>Laravel</h2>
                                    </div>
                                    <div className="loader">
                                        <div style={{ width: "60%" }} className="html-loader"></div>
                                    </div>
                                    <div className="percent">60%</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="html-container mt-2 ">
                                    <div className="bungkusan">
                                        <h2>Django</h2>
                                    </div>
                                    <div className="loader">
                                        <div style={{ width: "80%" }} className="html-loader"></div>
                                    </div>
                                    <div className="percent">80%</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="html-container mt-2 ">
                                    <div className="bungkusan">
                                        <h2>Node JS</h2>
                                    </div>
                                    <div className="loader">
                                        <div style={{ width: "75%" }} className="html-loader"></div>
                                    </div>
                                    <div className="percent">75%</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="html-container mt-2 ">
                                    <div className="bungkusan">
                                        <h2>expressJS</h2>
                                    </div>
                                    <div className="loader">
                                        <div style={{ width: "80%" }} className="html-loader"></div>
                                    </div>
                                    <div className="percent">80%</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="html-container mt-2 ">
                                    <div className="bungkusan">
                                        <h2>SQL</h2>
                                    </div>
                                    <div className="loader">
                                        <div style={{ width: "90%" }} className="html-loader"></div>
                                    </div>
                                    <div className="percent">90%</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="html-container mt-2 ">
                                    <div className="bungkusan">
                                        <h2>mongodb</h2>
                                    </div>
                                    <div className="loader">
                                        <div style={{ width: "60%" }} className="html-loader"></div>
                                    </div>
                                    <div className="percent">60%</div>
                                </div>
                            </div>
                        </div>

                    </div>




                </div>
                <div className="row">
                    <p className="mt-5 text-center mx-2 worknow">skills cannot be measured by numbers alone, to clarify my skills please see my work that I have done</p>
                </div>
            </div >
        )
    }
}

export default Skill;