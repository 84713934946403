import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../assets/css/main.css';
import iconbackend from '../assets/icon/backend.svg';
import iconapi from '../assets/icon/api.svg';
import iconfrontend from '../assets/icon/frontend.svg';
import iconux from '../assets/icon/ux.svg';


function Aboutme() {
    return (

        <div>
            <div className="row mt-4">
                <div className="col-12">
                    <h5 className="bawahmenuh5">About me</h5>
                    <hr className="bawahmenu" />
                </div>
            </div>
            <div className="container">
                <div className="row ">
                    <div className="col-12 isian">
                        <div className="row">
                            <div className="col-12">
                                <p className="text-about">Hello my name is Brillian Puja Mukti Wicaksana, you can
                                    call me Brillian. I
                                    am very interested in technology, one of which is how the technology was
                                    created. I am very happy to learn new things and explore them. The web is
                                    one of the things that interests me the most since I was a child.
                                        </p>
                            </div>
                            <div className="col-12 mt-2">
                                <h5 className="bawahmenuh5">
                                    What i do now ?
                                        </h5>
                                <hr className="bawahmenu" />
                            </div>
                        </div>
                        <div className="row justify-content-center text-center">
                            <div className="col-md-5 col-12 kegiatan-sekarang">
                                <div className="row align-items-center d-flex justify-content-center">
                                    <div className="col-12 col-md-3 ">
                                        <i className=" fas fa-laptop-code fa-4x"></i>
                                    </div>
                                    <div className="isian-kegiatan col-md-8 col-12">

                                        <h6 className=" bawahmenuh5 ">Learn Code</h6>
                                        <small className="detail-kegiatan">Learn new technology and explore</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 kegiatan-sekarang">
                                <div className="row align-items-center d-flex justify-content-center">
                                    <div className="col-12 col-md-3">
                                        <i className="fas fa-book fa-4x"></i>
                                    </div>
                                    <div className="isian-kegiatan col-md-8 col-12">

                                        <h6 className="bawahmenuh5 ">College student</h6>
                                        <small className="detail-kegiatan">im still studying in my college</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 kegiatan-sekarang">
                                <div className="row align-items-center d-flex justify-content-center">
                                    <div className="col-12 col-md-3">
                                        <i className="fas fa-user-tie fa-4x"></i>
                                    </div>
                                    <div className="isian-kegiatan col-md-8 col-12">

                                        <h6 className="bawahmenuh5 ">Freelance</h6>
                                        <small className="detail-kegiatan">work on the sidelines of
                                                    time</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 kegiatan-sekarang">
                                <div className="row align-items-center d-flex justify-content-center">
                                    <div className="col-12 col-md-3">
                                        <i className="fas fa-compass fa-4x"></i>
                                    </div>
                                    <div className="isian-kegiatan col-md-8 col-12">

                                        <h6 className="bawahmenuh5 ">Travelling</h6>
                                        <small className="detail-kegiatan">visiting places I've never
                                                    visited</small>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12 mt-2">
                                <h5 className="bawahmenuh5">
                                    What i can do ?
                                        </h5>
                                <hr className="bawahmenu" />
                            </div>

                        </div>
                        <div className="row justify-content-center text-center">
                            <div className="col-md-5 col-12 kegiatan-sekarang">
                                <div className="row align-items-center d-flex justify-content-center">
                                    <div className="col-12 col-md-3">
                                        <img alt='icon' src={iconux} className="icon"></img>
                                    </div>
                                    <div className="isian-kegiatan col-md-8 col-12">

                                        <h6 className="bawahmenuh5 ">Ui Design</h6>
                                        <small className="detail-kegiatan">I can design Apps or
                                                    website use any tools</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center text-center">
                            <div className="col-md-5 col-12  kegiatan-sekarang">
                                <div className="row align-items-center d-flex justify-content-center">
                                    <div className="col-12 col-md-3">
                                        <img alt="icon" src={iconfrontend} className="icon"></img>
                                    </div>
                                    <div className="isian-kegiatan col-md-8 col-12">

                                        <h6 className="bawahmenuh5 ">front end</h6>
                                        <small className="detail-kegiatan">Learn new technology and explore</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 kegiatan-sekarang">
                                <div className="row align-items-center d-flex justify-content-center">
                                    <div className="col-12 col-md-3">
                                        <img alt="icon" src={iconbackend} className="icon"></img>
                                    </div>
                                    <div className="isian-kegiatan col-md-8 col-12">

                                        <h6 className="bawahmenuh5 ">Back end</h6>
                                        <small className="detail-kegiatan">Learn new technology and explore</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center text-center">
                            <div className="col-md-5 col-12 kegiatan-sekarang">
                                <div className="row align-items-center d-flex justify-content-center">
                                    <div className="col-12 col-md-3">
                                        <img alt="icon" className="icon" src={iconapi}></img>
                                    </div>
                                    <div className="isian-kegiatan col-md-8 col-12">

                                        <h6 className="bawahmenuh5 ">Rest API</h6>
                                        <small className="detail-kegiatan">Create restful API with express js and any dbms</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <hr className="mt-5 bawahmenu " />

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
export default Aboutme;