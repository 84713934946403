import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../assets/css/main.css';
import { BrowserRouter, Route, NavLink } from 'react-router-dom';
import Aboutme from '../components/Aboutme';
import Resume from '../components/Resume';
import Mywork from '../components/Mywork';
import Touchme from '../components/Touchme';

class sidebarkanan extends Component {
    render() {
        return (
            <BrowserRouter>


                <div>
                    <div className="row justify-content-center menu-induk mt-2">

                        <NavLink exact to="/" className="menu" activeclassname="active" >
                            <div className="" >
                                <p className="text-center ">About</p>
                            </div>
                        </NavLink>
                        <NavLink to="/Resume" className="menu" activeclassname="active">
                            <div className="">
                                <p className="text-center">Resume </p>
                            </div>
                        </NavLink>
                        <NavLink to="/Mywork" className="menu" activeclassname="active">
                            <div className="">
                                <p className="text-center">My work</p>
                            </div>
                        </NavLink>

                        <NavLink to="/Touchme" className="menu" activeclassname="active">
                            <div className="">
                                <p className="text-center">Touch Me</p>
                            </div>
                        </NavLink>
                    </div>



                </div>

                <main>
                    <Route path='/' exact component={Aboutme} />
                    <Route path='/Resume' exact component={Resume} />
                    <Route path='/Mywork' exact component={Mywork} />
                    <Route path='/Touchme' exact component={Touchme} />

                </main>
            </BrowserRouter >

        )
    }
}
export default sidebarkanan;