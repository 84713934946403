import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../assets/css/main.css';
import Profile from './profile-resume';
import Education from './education-resume';
import Experience from './experience-resume';
import Hobby from './hobby-resume';
import Skill from './skill-resume';



class Resume extends Component {
    render() {


        return (
            <div>
                <div className="row mt-4">
                    <div className="col-12">
                        <h5 className="bawahmenuh5">Resume</h5>
                        <hr className="bawahmenu" />
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 isian ">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    {/* awal profile */}
                                    <Profile />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Education />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Experience />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Hobby />
                                </div>

                            </div>
                            <div className="row mb-5">
                                <div className="col-12">
                                    <Skill />


                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <hr className="mt-5 bawahmenu " />

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Resume;